import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/fruit-sniper/privacy-policy",
		name: "Fruit Sniper - Privacy Policy",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "privacyPolicy" */ "../views/fruit-sniper/PrivacyPolicy.vue"),
	},
	{
		path: "/fruit-sniper/terms-of-service",
		name: "Fruit Sniper - Terms Of Service",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "privacyPolicy" */ "../views/fruit-sniper/TermsOfService.vue"),
	},
	{
		path: "/:game/privacy-policy",
		name: "Privacy Policy",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "privacyPolicy" */ "../views/PrivacyPolicy.vue"),
	},
	{
		path: "/:game/terms-of-service",
		name: "Terms Of Service",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "privacyPolicy" */ "../views/TermsOfService.vue"),
	},
	{
		path: '/:catchAll(.*)', 
		name: "NotFound",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach((to, from, next) => {
	document.title = to.name + " - Overspace Games";
	next();
});

export default router;
