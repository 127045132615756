<template>
	<div id="app">
		<Navigation></Navigation>
		<router-view />
		<Footer></Footer>
	</div>
</template>

<script>
	import Navigation from "@/components/Navigation.vue";
	import Footer from "@/components/Footer.vue";

	export default {
		components: {
			Navigation,
      Footer
		},
	};
</script>

<style>
  @import url("https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css");
</style>